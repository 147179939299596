@font-face {
  font-family: 'Code Next';
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src: url('./fonts/CodeNext-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url('./fonts/NunitoSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-display: block;
  src: url('./fonts/NunitoSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src: url('./fonts/NunitoSans-Bold.ttf') format('truetype');
}
