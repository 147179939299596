.base.base {
  display: inline-block;
  border-radius: 100px;
  padding: 0.5rem 1.25rem;
  cursor: pointer;
  text-align: center;
}

.base.primary {
  background-color: var(--chakra-colors-chonky-500);
  color: white;
  font-weight: bold;
  box-shadow: inset 0px 3px 0px rgba(255, 255, 255, 0.2);
  border: 1px solid var(--chakra-colors-chonky-600);
  padding: 0.5rem 1.5rem;
}
.base.primary:hover {
  background-color: var(--chakra-colors-chonky-600);
}
.base.primary:active {
  background-color: var(--chakra-colors-chonky-700);
}
.base.primary:disabled,
.base.primary:hover[disabled] {
  opacity: 50%;
  cursor: default;
  background-color: var(--chakra-colors-chonky-500);
}

.base.secondary {
  background-color: transparent;
  color: inherit;
  font-weight: bold;
  padding: 0.5rem 1.5rem;
}
.base.secondary:hover {
  background-color: rgba(0, 0, 30, 0.1);
}
.base.secondary:active {
  background-color: rgba(0, 0, 30, 0.2);
}

.base.danger {
  background-color: var(--chakra-colors-kirby-500);
  color: white;
  font-weight: bold;
  border: 1px solid var(--chakra-colors-kirby-600);
  padding: 0.5rem 1.5rem;
}
.base.danger:hover {
  background-color: var(--chakra-colors-kirby-600);
}
.base.danger:active {
  background-color: var(--chakra-colors-kirby-700);
}
.base.danger:disabled,
.base.danger:hover[disabled] {
  opacity: 50%;
  cursor: default;
  background-color: var(--chakra-colors-kirby-500);
}

.base.outline {
  background-color: transparent;
  color: inherit;
  font-weight: bold;
  border: 1.5px solid var(--chakra-colors-cloud-400);
}
.base.outline:hover {
  background-color: rgba(0, 0, 30, 0.1);
}
.base.outline:active {
  background-color: rgba(0, 0, 30, 0.2);
}
.base.outline:disabled,
.base.outline:hover[disabled] {
  background-color: transparent;
  opacity: 50%;
  cursor: default;
}

.base.secondary-action {
  background-color: transparent;
  color: var(--chakra-colors-chonky-600);
  font-weight: 700;
  padding: 0 0.5rem;
  border-radius: 0;
}
.base.secondary-action:hover {
  color: var(--chakra-colors-chonky-700);
}

.base.link {
  background-color: transparent;
  text-decoration: underline;
  color: var(--chakra-colors-chonky-600);
  padding: 0 0.5rem;
  border-radius: 0;
}
.base.link:hover {
  color: var(--chakra-colors-chonky-700);
}

.base.link-danger {
  background-color: transparent;
  color: var(--chakra-colors-kirby-500);
  padding: 0;
  border-radius: 0;
}
.base.link-danger:hover {
  color: var(--chakra-colors-kirby-700);
}

.base.link-on-dark {
  background-color: transparent;
  text-decoration: underline;
  color: white;
  padding: 0 0.5rem;
  border-radius: 0;
}
.base.link-on-dark:hover {
  text-decoration: none;
}

.base.ghost-tame {
  background-color: rgba(0, 0, 30, 0.05);
  color: var(--chakra-colors-cloud-800);
  font-weight: bold;
}

.base.ghost {
  background-color: rgba(0, 0, 30, 0.05);
  color: var(--chakra-colors-spyro-600);
  font-weight: bold;
}
.base.ghost:hover {
  background-color: rgba(0, 0, 30, 0.1);
}
.base.ghost:disabled,
.base.ghost:hover[disabled] {
  background-color: rgba(0, 0, 30, 0.05);
  opacity: 50%;
  cursor: default;
}

.base.ghost-on-dark {
  background-color: rgba(235, 235, 255, 0.3);
  font-weight: bold;
  color: white;
}
.base.ghost-on-dark:hover {
  background-color: rgba(235, 235, 255, 0.4);
}
.base.ghost-on-dark:disabled,
.base.ghost-on-dark:hover[disabled] {
  background-color: rgba(235, 235, 255, 0.3);
  opacity: 50%;
  cursor: default;
}

.base.round {
  padding: 0 0 3px 0;
}

.base :global(.chakra-button__spinner) {
  float: left;
}
.base:has(:global(.chakra-button__icon)) {
  display: inline-flex;
  align-items: center;
}

.tooltip.tooltip {
  background-color: white;
  border: 1px solid var(--chakra-colors-cloud-300);
  border-radius: 8px;
  color: var(--chakra-colors-text-primary);
  padding: 20px;
}
