.react-calendar-heatmap .color-empty {
  fill: #d0d2e5;
}
.react-calendar-heatmap .color-scale-1 {
  fill: #9aebfb;
}
.react-calendar-heatmap .color-scale-2 {
  fill: #44d0ef;
}
.react-calendar-heatmap .color-scale-3 {
  fill: #1389a4;
}
.react-calendar-heatmap .color-scale-4 {
  fill: #0a5b6c;
}
/*
 * react-calendar-heatmap styles
 *
 * All of the styles in this file are optional and configurable!
 * The github and gitlab color scales are provided for reference.
 */

.react-calendar-heatmap text {
  font-size: 10px;
  fill: #aaa;
}

.react-calendar-heatmap .react-calendar-heatmap-small-text {
  font-size: 5px;
}

.react-calendar-heatmap rect:hover {
  stroke: #555;
  stroke-width: 1px;
}
